.app {
    background-color: var(--color-neutral-white);
    padding-bottom: 155px;
}


/***

  Location box with address

***/

.location {
    position: relative;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-end;
    height: 100px;
    padding: 0 32px 12px 20px;
    background-color: var(--color-primary-mer-blue);
    overflow: hidden;
}

.location-content {
    position: relative;
    z-index: 3;
    width: 100%;
}

.location-logo {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 148px;
    height: 100px;
}

.location-content > h2,
.location-content > address {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--color-neutral-white);
}
.location-content > address {
    font-style: normal;
    font-family: var(--font--normal);
    font-weight: var(--font-weight-normal);
    color: var(--color-neutral-white);
    font-size: 1.6rem;
    line-height: 125%;
}



.choice {
    margin: 20px;
}

@media screen and (max-width: 320px) {
    .choice {
        margin: 20px 16px 20px 16px;
    }
}

.choice > p {
    font-size: 1.6rem;
    line-height: 150%;
    color: var(--color-on-white-dark-gray);
}

.choice > h3 {
    margin-bottom: 4px;
}

.choice--charger > p {
    margin-bottom: 16px;
}

.charger-box {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 0 -8px 0 -16px;
}

@media screen and (max-width: 370px) {
    .charger-box {
        display: block;
        margin: 0 -8px;
    }
    .charger-icon {
        display: none;
    }
}

.charger-icon-line {
    fill: var(--color-on-white-dark-gray);
}

.charger-box--active .charger-icon-line {
    fill: var(--color-primary-mer-blue);
}

.charger-options {
    flex-grow: 1;
    flex-shrink: 20;
    list-style: none;
    border-radius: 6px;
    border: 1px solid var(--color-on-white-dark-gray);
}

.charger-seperator {
    height: 1px;
    border-top: 1px solid var(--color-on-white-dark-gray);
    line-height: 0px;
}

.charger-option {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 56px;
    padding: 0 12px;
}
.charger-option--active {
    animation: pulse 0.15s ease-in-out;
}
@keyframes pulse {
    0% {
        background-color: transparent;
    }
    50% {
        background-color: var(--color-illustration-light-blue);
    }
    100% {
        background-color: transparent;
    }
}


.charger-id {
    width: 3.2em;
    color: var(--color-neutral-black);
    font-size: 1.4rem;
    font-weight: var(--font-weight-bold);
}

.charger-symbol {
    width: 24px;
    margin-left: 8px;
}

.charger-info {
    flex-grow: 2;
    margin-left: 8px;
    overflow: hidden;
}
.charger-info > h4 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 1.4rem;
    line-height: 143%;
    font-weight: var(--font-weight-normal);
}
.charger-info > p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 1rem;
    line-height: 180%;
}

.charger-power {
    margin-left: 8px;
    width: calc(2.2em);
    font-size: 1.2rem;
    line-height: 143%;
}
.charger-power > span {
    font-weight: var(--font-weight-bold);
}

.charger-check {
    margin-left: 8px;
}


.charger-option .charger-symbol-line {
    stroke: var(--color-on-white-dark-gray);
}
.charger-option .charger-check-circle {
    fill: var(--color-on-white-dark-gray);
}
.charger-option .charger-check-mark {
    fill: none;
}


.charger-option--busy::before {
    content: "";
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border: 1px solid var(--color-secondary-red);
}

.charger-option:last-child.charger-option--busy::before {
    border-radius: 6px 6px 6px 6px;
}
.charger-option:nth-child(n+1):last-child.charger-option--busy::before {
    border-radius: 0 0 6px 6px;
}

.charger-option--busy > * {
    color: var(--color-on-white-dark-gray);
}
.charger-option--busy .charger-info > p {
    font-size: 1.2rem;
    color: var(--color-secondary-red);
    font-weight: var(--font-weight-bold);
}
.charger-option--busy .charger-check-circle {
    opacity: 0.1;
}


.charger-option--active::before {
    content: "";
    position: absolute;
    top: -1px;
    right: -2px;
    bottom: -1px;
    left: -2px;
    z-index: 2;
    border: 2px solid var(--color-primary-mer-blue);
}
.charger-option:first-child.charger-option--active::before {
    border-radius: 6px 6px 0 0;
}
.charger-option:last-child.charger-option--active::before {
    border-radius: 6px 6px 6px 6px;
}
.charger-option:nth-child(n+1):last-child.charger-option--active::before {
    border-radius: 0 0 6px 6px;
}
.charger-option--active .charger-id,
.charger-option--active .charger-info > h4 {
    color: var(--color-primary-mer-blue);
    font-weight: var(--font-weight-bold);
}
.charger-option--active .charger-check-circle,
.charger-option--active .charger-check-mark {
    fill: var(--color-primary-mer-blue);
}





/*
.charger-option--busy .charger-box,
.charger-option--busy .charger-seperator {
  border-color: var(--color-secondary-red);
}
.charger-option--busy .charger-check svg {
  visibility: hidden;
}
.charger-option--busy p {
  color: var(--color-secondary-red);
}
*/






/***

  VIPPS floating pay box

***/

.pay-box {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    height: 170px;
    padding: 20px 32px;
    border: 0;
    border-top: 4px solid;
    border-image: var(--color-gradient-buy-line) 1;
    background-color: var(--color-primary-mer-blue);
    color: white;
}
@media screen and (max-width: 320px) {
    .pay-box {
        padding: 20px 16px;
    }
}
@media screen and (min-width: 480px) {
    .pay-box {
        width: 480px;
        margin: 0 auto;
    }
}

.pay-box-info {
    position: relative;
}

.pay-box-text {
    transition: all 0.35s ease-in-out;
}

.pay-box-text > h3 {
    line-height: 143%;
    font-family: var(--font);
    font-weight: var(--font-weight-bold);
}

.pay-box-text > p {
    font-family: var(--font);
    font-weight: var(--font-weight-normal);
    font-size: 1.4rem;
    line-height: 143%;
}

.pay-box-symbol-line {
    stroke: var(--color-neutral-white);
}



.pay-box-info--ready .pay-box-text {
    width: calc(100% - 44px);
    opacity: 1;
    transform: translateX(44px);
}
.pay-box-info svg {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 0.35s ease-in-out;
    transform: translateX(-60px);
}
.pay-box-info--ready svg {
    opacity: 1;
    transform: translateX(0);
}



.vipps-button {
    width: 100%;
    height: 48px;
    margin-top: 20px;
    background-color: var(--color-vipps-orange);
    font-family: var(--font);
    font-weight: var(--font-weight-bold);
    font-size: 1.8rem;
}

.vipps-button-content {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    color: var(--color-neutral-white);
    transition: all 0.35s ease-in-out;
}
.vipps-button-content img {
    margin-left: 8px;
}
.vipps-button[disabled] .vipps-button-content {
    opacity: 0.5;
}