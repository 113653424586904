.error-container {
    min-height: 100vh;
}

@media screen and (min-width: 480px) {
    .error-container {
        width: 480px;
        margin: 0 auto;
    }
}


.try-again-button{
    background-color: #0000C2;
    border: solid 1rem #0000C2 !important;
    color: white;
}