body {
    background: var(--color-primary-mer-blue) linear-gradient(180deg, #0B78D5 0%, var(--color-primary-mer-blue) 70%);
    background-attachment: fixed;
}

.res-container{
    background-color: #1200c2;
    position: absolute;
    height: 100vh;
    width: 100vw;
}
.res-app {
    padding: 16px 24px;
}

.res-app {
    min-height: 100vh;
}

@media screen and (min-width: 480px) {
    .res-app {
        width: 480px;
        margin: 0 auto;
    }
}

@media screen and (min-width: 1000px) {
    .res-app {
        width: 480px;
        padding-top: 10rem;
        margin: 0 auto;

    }
}



@media screen and (max-width: 320px) {
    .res-app {
        padding: 16px 16px;
    }
}


/***

  Receipt box

***/

.receipt {
    display: flex;
    flex-flow: column;
    align-items: center;
    position: relative;
    height: 380px;
    padding: 40px 24px 24px 24px;
    background-color: var(--color-primary-mer-dark-blue);
    border: 1px solid var(--color-on-blue-dividers);
    box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.08);
    color: var(--color-neutral-white);
}

.receipt::before {
    content: "";
    position: absolute;
    top: -1px;
    left: 50%;
    z-index: 2;
    width: 40px;
    height: 20px;
    transform: translateX(-50%);
    border-radius: 0 0 20px 20px;
    background-color: #0B73D0;
}

.logo {
    margin: 0 auto;
}
.receipt h1 {
    margin-top: 8px;
    line-height: 121%;
}

.tag {
    font-size: 1.4rem;
}


.details {
    width: 100%;
    margin-top: 32px;
}

.details li {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}
.details-sum {
    justify-content: space-between;
    font-weight: var(--font-weight-bold);
    font-size: 1.8rem;
    line-height: 144%;
}
.details-vat {
    margin-top: 2px;
    font-size: 1.4rem;
    line-height: 144%;
}
.details-seperator {
    height: 1px;
    margin: 10px 0 16px 0;
    background-color: var(--color-on-blue-dividers);
    font-size: 0px;
}
.details-facts {
    justify-content: space-between;
    margin-top: 2px;
    font-size: 1.6rem;
    line-height: 137%;
}
.details-small {
    margin-top: 2px;
    color: var(--color-on-white-light-gray);
    font-size: 1.4rem;
    line-height: 144%;
}
.details-small-link{
    color: #10acd6;
}
.details-card {
    margin-left: 8px;
}
.details-seperator-10 {
    font-size: 0px;
    height: 10px;
}



/***

  Get app button

***/

.get-app-title{
    color: var(--color-neutral-white);
    font-size: 2rem;
    font-weight: bold;
    padding: 0.5rem;
    margin-top: 1rem;
}

.get-app {
    display: flex;
    flex-flow: row nowrap;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 64px;
    margin-top: 8px;
    padding: 0 12px;
    border: 2px solid var(--color-neutral-white);
    color: var(--color-neutral-white);
    font-size: 1.4rem;
    line-height: 128%;
    text-align: left;
}
.get-app img {
    flex-shrink: 1;
}
.get-app img:first-child {
    margin-right: 16px;
}
.get-app img:last-child {
    margin-left: 12px;
}



/***

  Feedback trigger

***/

.feedback {
    display: none;
    margin-top: 32px;
}
.feedback--active {
    display: block;
}
.feedback p {
    color: var(--color-neutral-white);
    font-size: 1.6rem;
    line-height: 128%;
    text-align: center;
}
.feedback-options {
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 12px;
}
.feedback-option img {
    width: 64px;
    height: 64px;
}
.feedback-option--animate {
    animation: jump 1.2s ;

}

@keyframes jump {
    0% {
        transform: translateY(4px) scale(1.25, 0.75);
        transform-origin: bottom center;
    }
    40% {
        transform: translateY(-30px) scale(1, 1);
        transform-origin: center center;
    }
    45% {
        transform: translateY(-30px) rotate(20deg);
        transform-origin: center center;
    }
    50% {
        transform: translateY(-30px) rotate(-20deg);
        transform-origin: center center;
    }
    55% {
        transform: translateY(-30px) rotate(20deg);
        transform-origin: center center;
    }
    70% {
        transform: translateY(-30px) rotate(-20deg);
        transform-origin: center center;
    }
    80% {
        transform: translateY(8px) scale(0.9, 1.1);
        transform-origin: bottom center;
    }
    90% {
        transform: translateY(0) scale(1.25, 0.75);
        transform-origin: bottom center;
    }
}


/***

  Feedback thank-you

***/
.feedback-thanks {
    display: none;
    margin-top: 32px;
    text-align: center;
}
.feedback-thanks--active {
    display: block;
}
.feedback-thanks h2 {
    color: var(--color-neutral-white);
    line-height: 144%;
}
.feedback-thanks p {
    margin-top: 4px;
    color: var(--color-illustration-light-blue);
    font-size: 1.6rem;
    line-height: 136%;
}

/***

  Feedback form

***/

.feedback-form {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -10;
    padding: 40px 32px;
    background-color: var(--color-primary-mer-blue);
    color: var(--color-illustration-light-blue);
    opacity: 0;
    transform: scale(0.01, 0.01);
    border-radius: 100%;
    transition: all 0.6s cubic-bezier(0.175, -0.885, 0.32, 1.475);
}
.feedback-form--active {
    z-index: 10;
    transform: scale(1, 1);
    opacity: 1;
    border-radius: 0;
}
.feedback-form--gone {
    z-index: -1;
    border-radius: 100%;
    transform: scale(0.01, 0.01);
    opacity: 0;
}

.feedback-form h2 {
    margin-top: 32px;
    line-height: 144%;
}
.feedback-form p {
    margin-top: 2px;
    font-size: 1.8rem;
    line-height: 144%;
}

.feedback-msg {
    width: 100%;
    height: 120px;
    margin-top: 24px;
    padding: 16px 20px;
    border: 1px solid var(--color-primary-mer-turquoise);
    box-shadow: 0px 0px 5px 1px rgba(66, 254, 255, 0.8);
    font-size: 1.8rem;
    line-height: 144%;
    color: var(--color-neutral-white);
    -webkit-touch-callout: default;
    -webkit-user-select: auto;
    user-select: auto;
}

@media screen and (max-width: 480px) {
    .feedback-form--active {
       height: 100vh;
    }
}

.feedback-msg::placeholder {
    color: var(--color-illustration-light-blue);
}

.feedback-actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    margin: 16px 0 0 auto;
}

.feedback-cancel,
.feedback-submit {
    height: 32px;
    padding: 0 24px;
    font-size: 1.4rem;
    line-height: 144%;
}
.feedback-cancel {
    margin: 0 8px 0 auto;
    color: var(--color-illustration-light-blue);
}
.feedback-submit {
    margin: 0 0 0 auto;
    border: 1px solid var(--color-primary-mer-turquoise);
    color: var(--color-neutral-white);
}