.container {
    margin: 100px auto;
    position: relative;
    width: 100px;
}
.loading-bar-container {
    height: 4px;
    width: 100%;
    background-color: #1200c2;
    position: absolute;
    overflow: hidden;
}

.loading-bar {
    height: 100%;
    width: 50%;
    background-color: #0000ff;
    background-image: linear-gradient(to right, white, #1200c2);
    position: absolute;
    left: -50%;
    animation: loading 2s ease-in 0.5s infinite;
}

@keyframes loading {
    0% {
        transform:translateX(0)
    }
    to {
        transform:translateX(400%)
    }
}