
.session-app {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 20px 24px 104px;
    background-color: transparent;
}

@media screen and (min-height: 600px) {
    .session-app {
        position: absolute;
        top: 50%;
        width: 100%;
        min-height: auto;
        transform: translateY(-50%);
    }
}


/***

  Top

***/

h2 {
    color: var(--color-neutral-white);
    line-height: 142%;
}

.session-charger-info {
    display: flex;
    flex-flow: row nowrap;
    margin-top: 2px;
}

.session-charger-info .session-charger-symbol-line {
    stroke: var(--color-on-white-light-gray);
}
.session-charger-info p {
    margin-left: 8px;
    color: var(--color-on-white-light-gray);
    font-size: 1.6rem;
    line-height: 2.0rem;
}



/***

  Dots with animation

***/

.session-dots {
    list-style: none;
    margin-top: 20px;
}
.session-dot {
    width: 8px;
    height: 8px;
    color: var(--color-neutral-white);
    font-weight: var(--font-weight-bold);
    line-height: 4px;
    font-size: 1.8rem;
    transition: all 0s;
}
.session-dot--active {
    color: var(--color-primary-mer-turquoise);
}


.session-dot {
    animation: dots 1.25s linear infinite;
}
.session-dot-2 {
    animation-delay: 125ms
}
.session-dot-3 {
    animation-delay: 250ms
}
.session-dot-4 {
    animation-delay: 375ms
}

@keyframes dots {
    0% {
        color: var(--color-primary-mer-turquoise);
    }
    49% {
        color: var(--color-primary-mer-turquoise);
    }
    50% {
        color: var(--color-neutral-white);
    }
    100% {
        color: var(--color-neutral-white);
    }
}




/***

  Car with animation

***/

.session-car-animation {
    position: relative;
    margin-top: 20px;
    width: 66px;
    height: 147px;
    overflow: hidden;
}
.session-car-symbol {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
}
.session-car-gradient-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 28px;
    overflow: hidden;
}
.session-car-gradient {
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: 200%;
    padding: 0 5px;
    animation: gradients 2.5s linear infinite;
}
.session-car-gradient li {
    width: 100%;
    height: 200%;
    background: linear-gradient(180deg, var(--color-primary-mer-blue) 20%, var(--color-primary-mer-turquoise) 50%, var(--color-primary-mer-blue) 80%);
    opacity: 0.6;
}

@keyframes gradients {
    from {
        transform: translateY(calc(-200% - 160px));
    }
    to {
        transform: translateY(calc(200% + 160px));
    }
}

.session-car-gradient-1 {
    margin-right: 1.5px;
}
.session-car-gradient-2 {
    margin-right: 1.5px;
    transform: translateY(-80px);
}
.session-car-gradient-3 {
    transform: translateY(-160px);
}



/***

  Time + charging info

***/

.session-time {
    margin-top: 20px;
    color: var(--color-primary-mer-turquoise);
    font-weight: var(--font-weight-bold);
    font-size: 4.8rem;
    line-height: 121%;
}

.session-info {
    margin-top: 20px;
    color: var(--color-neutral-white);
    font-weight: var(--font-weight-bold);
    font-size: 1.8rem;
    line-height: 144%;
    text-align: center;
}


/***

  Floating stop button

***/
.session-stop-box {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 24px 20px 24px;
}
@media screen and (min-width: 480px) {
    .session-stop-box {
        width: 480px;
        margin: 0 auto;
    }
}

.session-stop-box--active {
    opacity: 0.7;
    pointer-events: none;
}

.session-stop {
    width: 100%;
    padding: 20px 0 18px 0;
    background-color: var(--color-secondary-red);
    color: var(--color-neutral-white);
    font-weight: var(--font-weight-bold);
    font-size: 1.8rem;
    line-height: 144%;
}



.not-found-modal{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 400px;
    border: 1px solid white;
    padding: 20px;
    background-color: var(--color-secondary-red);
    color: var(--color-neutral-white);
    font-weight: var(--font-weight-bold);
}