@tailwind base;
@tailwind components;
@tailwind utilities;
/*body {*/
/*  margin: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/


/***

  Import font

***/

@font-face {
  font-family: Sharp;
  src: url("assets/SharpSans-Bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: Sharp;
  src: url("assets/SharpSans-Semibold.woff") format("woff");
  font-weight: 600;
}


/***

  Resets

***/

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  user-select: none;
  background-color: transparent;
  border-radius: 0;
  -webkit-appearance: none;
  appearance: none;
  -webkit-font-smoothing: antialiased;
}
*:not(textarea) {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}


/***

  Global variables

***/

:root {
  --color-primary-mer-blue: rgba(0, 0, 194, 1);
  --color-primary-mer-dark-blue: rgba(0, 0, 166, 1);
  --color-primary-mer-turquoise: rgba(20, 230, 230, 1);
  --color-secondary-red: rgba(229, 28, 92, 1);
  --color-neutral-white: rgba(255, 255, 255, 1);
  --color-neutral-black: rgba(33, 33, 33, 1);
  --color-on-white-dark-gray: rgba(118, 118, 118, 1);
  --color-on-white-light-gray: rgba(204, 204, 204, 1);
  --color-on-blue-dividers: rgba(73, 84, 242, 1);
  --color-on-blue-text: rgba(126, 136, 180, 1);
  --color-illustration-light-blue: rgba(177, 212, 248, 1);

  --color-vipps-orange: rgba(247, 88, 35, 1);
  --color-gradient-buy-line: linear-gradient(269.99deg, rgba(66, 254, 255, 1) 17.43%, rgba(0, 0, 200, 1) 92.79%);
  --color-gradient-location: linear-gradient(180deg, rgba(1, 1, 39, 0.135) 34.22%, rgba(0, 0, 52, 0.9) 88.21%);

  --font: Sharp, serif, system, -apple-system, ".SFNSText-Regular", "San Francisco", Roboto, "Segoe UI","Helvetica Neue", "Lucida Grande", sans-serif;;
  --font-weight-bold: 700;
  --font-weight-normal: 600;
}



/***

  Global stuff

***/


html {
  font-size: 10px;
}

body {
  min-height: 100vh;
  overflow-x: hidden;
  background-color: var(--color-primary-mer-blue);
  font-family: var(--font);
  font-weight: var(--font-weight-normal);
  color: var(--color-neutral-black);
}


h1 {
  font-weight: var(--font-weight-bold);
  font-size: 4.8rem;
  line-height: 133%;
}
h2 {
  font-weight: var(--font-weight-bold);
  font-size: 2.4rem;
  line-height: 133%;
}

h3 {
  font-weight: var(--font-weight-bold);
  font-size: 1.8rem;
  line-height: 133%;
}

button,
textarea {
  font-family: var(--font);
  font-weight: var(--font-weight-normal);
}

.app {
  min-height: 100vh;
}

@media screen and (min-width: 480px) {
  .app {
    width: 480px;
    margin: 0 auto;
  }
}